import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { store } from './store';
import { initialize } from '@sumologic/opentelemetry-rum';

if (
  process.env.REACT_APP_SUMOLOGIC_COLLECTION_SOURCE_URL &&
  process.env.REACT_APP_SUMOLOGIC_COLLECTION_SERVICE_NAME &&
  process.env.REACT_APP_SUMOLOGIC_COLLECTION_DEPLOYMENT_ENVIRONMENT &&
  process.env.REACT_APP_SUMOLOGIC_COLLECTION_APPLICATION_NAME &&
  process.env.REACT_APP_SUMOLOGIC_COLLECTION_API_URL
) {
  const urls = eval(process.env.REACT_APP_SUMOLOGIC_COLLECTION_API_URL);
  initialize({
    collectionSourceUrl: process.env.REACT_APP_SUMOLOGIC_COLLECTION_SOURCE_URL,
    serviceName: process.env.REACT_APP_SUMOLOGIC_COLLECTION_SERVICE_NAME,
    deploymentEnvironment:
      process.env.REACT_APP_SUMOLOGIC_COLLECTION_DEPLOYMENT_ENVIRONMENT,
    applicationName:
      process.env.REACT_APP_SUMOLOGIC_COLLECTION_APPLICATION_NAME,
    propagateTraceHeaderCorsUrls: urls as Array<string | RegExp>,
    samplingProbability: 1,
    collectErrors: true,
  });
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>
);
