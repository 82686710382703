import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import TabContent from '../../../../atoms/tab/TabContent';
import {
  IParticipantsReports,
  ITabContentProps,
} from '../../../../commons/types';
import { MRT_TableInstance } from 'material-react-table';
import Title from '../../../../atoms/title/Title';
import LeadersReportsTable from '../../../../organisms/tables/Company/Projects/ProjectDetails/LeadersReportsTable';
import DownloadActionIcon from '../../../../assets/img/icons/DownloadActionIcon.svg';
import RegenerateActionIcon from '../../../../assets/img/icons/RegenerateActionIcon.svg';
import Button from '../../../../atoms/button/Button';
import DownloadAllActionIcon from '../../../../assets/img/icons/DownloadAllActionIcon.svg';
import ReportRegenerationModal from '../../../../organisms/modals/Project/ProjectDetails/ReportRegenerationModal';
import { useActions } from '../../../../hooks/actions';
import { useAppSelector } from '../../../../hooks/redux';
import DownloadReportsModal from '../../../../organisms/modals/Project/ProjectDetails/DownloadReportsModal';
import { useParams } from 'react-router';
import {
  useGenerateReportMutation,
  useGetProjectByIdQuery,
} from '../../../../store/api/projects';
import { useGetClientByIdQuery } from '../../../../store/api/clients';
import { useGenerateHeidrickReportMutation } from '../../../../store/api/assessmentReports';
import { useGetAttachmentsByIdMutation } from '../../../../store/api/attachments';
import { useGetReportStructureQuery } from '../../../../store/api/reportStructure';

interface IProps {
  reportAttachmentsList: IParticipantsReports;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  width: '78%',
  gap: '15px',
  marginTop: '15px',
  marginBottom: '10px',
});

const StyledContentWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  border: '1px solid rgba(0, 33, 54, 0.2)',
  borderRadius: ' 4px 4px 0px 0px',
  padding: '6px 16px 10px',
});

const StyledLabel = styled('span')({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '29px',
  color: '#0B0C10',
});

const StyledActionWrapper = styled(Box)({
  display: 'flex',
  gap: '25px',
  alignItems: 'center',
});

const StyledActionLabel = styled('span')({
  fontFamily: 'Aventa',
  fontStyle: 'normal',
  fontWeight: 600,
  fontSize: '15px',
  lineHeight: '22px',
  color: '#18768C',
});

const StyledImg = styled('img')(() => ({
  position: 'relative',
  marginRight: '8px',
}));

export const LeaderReports = ({
  index,
  value,
  reportAttachmentsList,
}: ITabContentProps & IProps) => {
  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const refreshSelections = () => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.setRowSelection({});
  };

  const params = useParams();

  const isOpen = useAppSelector((state) => state.reportGenerationModal.isOpen);

  const [isReportsLoading, setIsReportsLoading] =
    React.useState<boolean>(false);
  const [attachmentData, setAttachmentData] = React.useState<any>([]);

  const isOpenDownloadModal = useAppSelector(
    (state) => state.downloadReportsModal.isOpen
  );

  const { data: { data: clientData = [] } = {} } = useGetClientByIdQuery(
    params.id as string
  );

  const { data: response } = useGetProjectByIdQuery(params.projectId as string);
  const projectData = response?.data?.at(0);

  const { data: reportStructureData, isLoading: isReportStructureLoading } =
    useGetReportStructureQuery({
      params: { clientId: params.id },
    });

  const [getAttachmentDetails, { isLoading: isAttachmentsLoading }] =
    useGetAttachmentsByIdMutation();
  const [generateReport] = useGenerateReportMutation();
  const [generateHeidrickReport] = useGenerateHeidrickReportMutation();

  const {
    openProjectModal,
    closeProjectModal,
    closeLeadersReportGenerateModal,
    openDownloadReportsModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const [rowSelection, setRowSelection] = React.useState<Array<any>>([]);

  const reportDownloadHandle = () => {
    openDownloadReportsModal(rowSelection);
  };

  const allReportDownloadHandle = () => {
    openDownloadReportsModal(attachmentData);
  };

  const regenerateReportHandle = () => {
    openProjectModal(rowSelection);
  };

  const handleClose = () => {
    closeProjectModal();
    closeLeadersReportGenerateModal();
  };

  const getAttachmentData = async (data: IParticipantsReports) => {
    const attachmentsData: any[] = [];

    await Promise.all(
      data.map(async (leader) => {
        await getAttachmentDetails(leader.reportId)
          .unwrap()
          .then((response) => {
            const temp = {
              _id: response.data[0].id,
              name: getReportInfo(response.data, data),
              email: getReportLeaderEmail(response.data, data),
              // needed this condition to show report name on both Heidrick and Insight record
              report:
                response.data[0]?.reportName || response.data[0]?.name || '-',
              type: response.data[0]?.reportType || '-',
              assessments: '-',
              // needed this condition to show Generated Date on both Heidrick and Insight record
              generateddate:
                response.data[0]?.reportDate ||
                response.data[0]?.lastUpdatedTs ||
                '-',
              generatedby: '-',
              leaderProfileId: getReportLeaderProfileId(response.data, data),
              reportType: response.data[0]?.reportType,
            };
            attachmentsData.push(temp);
          })
          .catch((e) => {
            console.log(e);
          });
      })
    );
    setAttachmentData(attachmentsData);
  };

  const getReportInfo = (attachmentData: any[], reportData: any[]) => {
    for (let i = 0; i < attachmentData.length; i++) {
      for (let j = 0; j < reportData.length; j++) {
        if (attachmentData[i].id === reportData[j].reportId) {
          return reportData[j].leader;
        }
      }
    }
  };

  const getReportLeaderEmail = (attachmentData: any[], reportData: any[]) => {
    for (let i = 0; i < attachmentData.length; i++) {
      for (let j = 0; j < reportData.length; j++) {
        if (attachmentData[i].id === reportData[j].reportId) {
          return reportData[j].leaderEmail;
        }
      }
    }
  };

  const getReportLeaderProfileId = (
    attachmentData: any[],
    reportData: any[]
  ) => {
    for (let i = 0; i < attachmentData.length; i++) {
      for (let j = 0; j < reportData.length; j++) {
        if (attachmentData[i].id === reportData[j].reportId) {
          return reportData[j].leaderProfileId;
        }
      }
    }
  };

  const reGenerateReports = () => {
    setIsReportsLoading(true);
    const regenerate = rowSelection.map(async (report) => {
      if (report.report.toLowerCase().includes('insight')) {
        try {
          const response = await generateReport({
            participantEmail: report.email,
            clientId: params.id || '', // send clientID instead of AccountID
            projectID: projectData?.insightsProject?.sourceProjectId ?? '',
            reportOptions: ['hideInhibEnhance'],
          }).unwrap();

          if (response.status == 'Ok') {
            toggleSuccessSnackbar({
              message: 'The report has been added to the queue for download.',
            });
          } else if (response.status == 'Error') {
            toggleErrorSnackbar({
              message: response.error,
            });
          } else {
            if (
              response.errors &&
              response.errors[Object.keys(response.errors)[0]].length
            ) {
              toggleErrorSnackbar({
                message: response.errors[Object.keys(response.errors)[0]][0],
              });
            }
          }
        } catch (err) {
          toggleErrorSnackbar({ message: 'Error regenerating reports' });
        }
      } else {
        try {
          const reportStructureObj =
            reportStructureData?.data?.filter(
              (reportObj: any) =>
                reportObj?.reportType?.toLowerCase() ===
                report?.reportType?.toLowerCase()
            ) || [];

          if (reportStructureObj?.length < 1) {
            toggleErrorSnackbar({
              message: 'Report structure id not present',
            });
          }

          const payload = {
            leaderProfileId: report?.leaderProfileId,
            reportType: report?.reportType?.toLowerCase(),
            accountId: params.id || '', // it is the clientID from URL
            projectId: params.projectId || '', // is is the proejct ID from URL
            clientId: params.id || '', // clientId for RBAC,
            reportStructureId: reportStructureObj[0]?._id || '',
          };

          const response = await generateHeidrickReport(payload).unwrap();
          if (response.status === 200) {
            toggleSuccessSnackbar({
              message: 'The report has been added to the queue for download.',
            });
          } else if (response.status === 400 || response.status === 404) {
            toggleErrorSnackbar({
              message: response.message,
            });
          } else {
            if (response.error && response.error) {
              toggleErrorSnackbar({
                message: response.error,
              });
            }
          }
        } catch (e: any) {
          toggleErrorSnackbar({ message: e.error });
        }
      }
    });

    Promise.all(regenerate).then(() => {
      setIsReportsLoading(false);
      handleClose();
    });
  };

  React.useEffect(() => {
    getAttachmentData(reportAttachmentsList);
  }, [reportAttachmentsList]);

  return (
    <TabContent
      index={index}
      value={value}
    >
      {isOpen && (
        <ReportRegenerationModal
          reGenerateReports={reGenerateReports}
          isLoading={isReportsLoading}
        />
      )}
      {isOpenDownloadModal && (
        <DownloadReportsModal refreshSelections={refreshSelections} />
      )}
      <StyledTitlesWrapper>
        <StyledTitleWrapper>
          <Title
            text={'Participant Reports'}
            sx={{
              fontSize: '28px',
            }}
          />
          <StyledHeaderActionWrapper>
            <Button
              btnType={'SECONDARY'}
              text={'Download All Generated Reports'}
              startIcon={<img src={DownloadAllActionIcon} />}
              onClick={allReportDownloadHandle}
            />
          </StyledHeaderActionWrapper>
        </StyledTitleWrapper>
      </StyledTitlesWrapper>

      {rowSelection.length ? (
        <StyledContentWrapper>
          <StyledLabel>{`${rowSelection.length} reports selected`}</StyledLabel>
          <StyledActionWrapper>
            <StyledActionLabel
              onClick={reportDownloadHandle}
              sx={{ cursor: 'pointer' }}
            >
              <StyledImg
                src={DownloadActionIcon}
                alt={'unchecked'}
              />
              Download Selected Reports
            </StyledActionLabel>
            <StyledActionLabel
              onClick={regenerateReportHandle}
              sx={{ cursor: 'pointer' }}
            >
              <StyledImg
                src={RegenerateActionIcon}
                alt={'unchecked'}
              />
              Re-Generate Selected Reports
            </StyledActionLabel>
          </StyledActionWrapper>
        </StyledContentWrapper>
      ) : (
        <div style={{ padding: '10px' }}>
          <StyledLabel>{`${attachmentData.length} total Participant reports`}</StyledLabel>
        </div>
      )}

      <LeadersReportsTable
        data={attachmentData || []}
        tableInstanceRef={tableInstanceRef}
        recordSelection={setRowSelection}
        isLoading={isAttachmentsLoading}
      />
    </TabContent>
  );
};
