import React from 'react';
import styled from '@emotion/styled';
import FormLabelSearchDropdown from '../../../molecules/form/FormLabelSearchDropdown';
import Modal from '../../../atoms/modal/Modal';
import ModalTitle from '../../../atoms/modal/ModalTitle';
import ModalBody from '../../../atoms/modal/ModalBody';
import ModalActions from '../../../atoms/modal/ModalActions';
import FormLabelInput from '../../../molecules/form/FormLabelInput';
import FormLabelDropdown from '../../../molecules/form/FormLabelDropdown';
import FormWrapper from '../../../molecules/form/FormWrapper';
import Button from '../../../atoms/button/Button';
import { useForm } from 'react-hook-form';
import {
  IRoleImpactStatementProps,
  SelectOptions,
} from '../../../commons/types';
import { useAppSelector } from '../../../hooks/redux';
import { useActions } from '../../../hooks/actions';
import ResetButton from '../../../atoms/button/ResetButton';
import { useParams } from 'react-router';
import FormInputSlider from '../../../molecules/form/FormInputSlider';
import TooltipBenchmarkData from '../../../atoms/tooltip/TooltipBenchmarkData';
import {
  useEditSkillByIdMutation,
  useGetImpactModuleQuery,
  useGetExpertiseCategoryQuery,
  useAddClientStatementMutation,
  useAddRoleToStatementMutation,
  useUpdateClientStatementMutation,
  useLazyGetRoleSkillsByCategoryQuery,
  useLazyGetClientStatementsQuery,
} from '../../../store/api/leadership';
import {
  IAddClientStatementRequest,
  IAddRoleToStatementRequest,
} from '../../../store/api/types/leadership';
import { filterDuplicateDropddownTypes } from '../../../utils/helpers';

type IProps = {
  roleFunctionID: string | undefined;
};

type IBenchmark = {
  proficiency: number;
};

const StyleStatementLink = styled('span')(() => ({
  fontFamily: 'Avenir',
  color: '#646a7c',
  textDecoration: 'underline',
}));

export default function FunctionalImpactRLModal({ roleFunctionID }: IProps) {
  const { id: clientId, roleid: roleId } = useParams();

  const [isNew, setNew] = React.useState<boolean>(true);
  const [statementDropdown, setStatementDropdown] = React.useState<
    Array<SelectOptions | null>
  >([]);

  const [statementMapping, setStatementMapping] = React.useState<
    Array<SelectOptions | null>
  >([]);

  const [addStatementToRole] = useAddRoleToStatementMutation();
  const [editStatement] = useUpdateClientStatementMutation();
  const [createStatement] = useAddClientStatementMutation();
  const [editRoleBenchmark] = useEditSkillByIdMutation();

  const { data: impactModuleData } = useGetImpactModuleQuery(
    clientId && roleId ? clientId : 'Master'
  );
  const { data: leadershipExpertiseData } = useGetExpertiseCategoryQuery(
    clientId && roleId ? clientId : 'Master'
  );

  const [getRoleStatements] = useLazyGetRoleSkillsByCategoryQuery();
  const [getClientStatements] = useLazyGetClientStatementsQuery();

  const initialValues = useAppSelector(
    (state) => state.functionalImpactRLModal.initialValues
  );
  const modalType = useAppSelector(
    (state) => state.functionalImpactRLModal.modalType
  );
  const isOpen = useAppSelector(
    (state) => state.functionalImpactRLModal.isOpen
  );

  const {
    closeFunctionalImpactRLModal,
    toggleSuccessSnackbar,
    toggleErrorSnackbar,
  } = useActions();

  const methods = useForm<IRoleImpactStatementProps>();
  const {
    handleSubmit,
    reset,
    formState: { errors },
    setValue,
  } = methods;

  const handleClose = () => {
    if (!isNew) setNew(true);
    reset(initialValues);
    closeFunctionalImpactRLModal();
  };

  React.useEffect(() => {
    reset(initialValues);
  }, [initialValues, isOpen, reset, isNew]);

  const statementList = React.useCallback(async () => {
    // Logic implemented for Statement Name Dropdown Values fileterd on Sub Function Value

    try {
      const roleStatements = await getRoleStatements({
        client: clientId && roleId ? clientId : 'Master',
        roleId: clientId && roleId ? roleId : clientId,
      }).unwrap();

      const clientStatements = await getClientStatements({
        skillGroup: 'Functional Impact',
        clientId: clientId && roleId ? clientId : 'Master',
      }).unwrap();

      // Filter Out Exsisting Statement from All Statements and Convert into dropdown

      const filteredStatements = clientStatements.data
        ?.filter(
          (clientStatement) =>
            !roleStatements?.functionalImpactData?.some(
              (roleStatement) =>
                roleStatement.statementLibraryUUID ===
                clientStatement.statementLibraryUUID
            )
        )
        .map((item) => ({
          text: item.name,
          value: item.statementLibraryUUID,
        }));

      setStatementDropdown(
        filterDuplicateDropddownTypes(
          filteredStatements as Array<SelectOptions>
        )
      );

      const filteredStatementsMapping = clientStatements.data
        ?.filter(
          (clientStatement) =>
            !roleStatements?.functionalImpactData?.some(
              (roleStatement) => roleStatement.id === clientStatement.id
            )
        )
        .map((item) => ({
          text: item.statementLibraryUUID,
          value: item.id,
        }));

      setStatementMapping(
        filterDuplicateDropddownTypes(
          filteredStatementsMapping as Array<SelectOptions>
        )
      );
    } catch (e) {
      setStatementDropdown([]);
    }
  }, [clientId, getClientStatements, getRoleStatements, roleId]);

  const submitHandler = async (
    formData: IAddClientStatementRequest,
    benchmarkPayload: IBenchmark
  ) => {
    const modalTypeMap = {
      edit: {
        fn: editStatement,
        benchmarkFn: editRoleBenchmark,
        messageSuccess: 'Statement Updated Successfully',
        errorMessage: 'Error Updating Statement',
      },
      add: {
        fn: createStatement,
        benchmarkFn: addStatementToRole,
        messageSuccess: 'Statement Added Successfully',
        errorMessage: 'Error Adding Statement',
      },
    };

    try {
      if (modalType === 'edit') {
        const payload = {
          benchmark: Number(benchmarkPayload.proficiency) || 1,
          id: formData.params.id || '',
        };
        await modalTypeMap[modalType].benchmarkFn(payload).unwrap();
      } else {
        const response = await modalTypeMap[modalType].fn(formData).unwrap();
        if (response && response.status === 'success' && response.data) {
          // getting ID of created statement
          const payload = {
            clientId: clientId && roleId ? clientId : 'Master',
            payload: {
              roleProfileId: clientId && roleId ? roleId : clientId,
              benchmark: Number(benchmarkPayload.proficiency) || 1,
              statementLibraryUUID: response.data.statementLibraryUUID,
              statementId: response.data.id,
              skillGroup: 'Functional Impact',
            },
          } as IAddRoleToStatementRequest;
          await modalTypeMap[modalType].benchmarkFn(payload).unwrap();
        }
      }
      toggleSuccessSnackbar({
        message: modalTypeMap[modalType].messageSuccess,
      });
      handleClose();
    } catch (e) {
      toggleErrorSnackbar({ message: modalTypeMap[modalType].errorMessage });
    }
  };

  React.useEffect(() => {
    if (!isNew && !statementDropdown.length) {
      statementList();
    }
  }, [isNew, statementDropdown, statementList]);

  const onSubmit = async (data: IRoleImpactStatementProps) => {
    const statementId = statementMapping?.filter(
      (obj: any) => obj?.text === data?.statementName?.value
    );

    if (!isNew) {
      const payload = {
        clientId: clientId && roleId ? clientId : 'Master',
        payload: {
          roleProfileId: clientId && roleId ? roleId : clientId,
          benchmark: Number(data.proficiency) || 1,
          statementLibraryUUID: data.statementName?.value || '',
          skillGroup: 'Functional Impact',
          statementId: statementId[0]?.value,
        },
      } as IAddRoleToStatementRequest;

      const res = await addStatementToRole(payload).unwrap();

      if (res.data && res.status === 'success') {
        setStatementDropdown([]);
        handleClose();
        toggleSuccessSnackbar({ message: 'Statement Added Successfully' });
      } else {
        toggleErrorSnackbar({ message: 'Error Adding Statement' });
      }
    } else {
      const client = clientId && roleId ? clientId : 'Master';
      const requestPayload: IAddClientStatementRequest = {
        params: {
          ...(data?.id && { id: data.id }),
          clientId: client,
          skillGroup: 'Functional Impact',
        },
        payload: {
          name: data.name,
          description: data.description,
          impactModuleCode: data.impact_area,
          expertiseCategoryCode: data.leadership_acumen,
        },
      };

      const benchmarkPayload: IBenchmark = { proficiency: data.proficiency };

      await submitHandler(requestPayload, benchmarkPayload);
    }
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      disableEscapeKeyDown={true}
    >
      <ModalTitle onClose={handleClose}>
        {modalType === 'add'
          ? `Add ${
              isNew ? 'a new' : 'existing'
            } ${roleFunctionID} impact statement`
          : 'Edit Statement'}
      </ModalTitle>
      <ModalBody>
        <StyleStatementLink>
          {modalType === 'add' && (
            <ResetButton
              onClick={() => {
                reset();
                setNew((value) => !value);
              }}
              text={`Add ${
                !isNew ? 'a new' : 'existing'
              } ${roleFunctionID} impact statement`}
              sx={{
                marginLeft: 0,
              }}
            />
          )}
        </StyleStatementLink>
        <FormWrapper
          methods={methods}
          id='add-new-role'
          onSubmit={handleSubmit(onSubmit)}
        >
          {isNew ? (
            <>
              <FormLabelDropdown
                name='impact_area'
                placeholder='Select impact module'
                rules={{
                  required:
                    modalType === 'add'
                      ? 'Please select an impact module'
                      : false,
                }}
                label='Impact module'
                tooltip='tooltip info'
                dropDownItem={impactModuleData?.dropdownList ?? []}
                defaultValue={
                  impactModuleData?.dropdownList
                    ? initialValues.impact_area
                    : ''
                }
                disabled={modalType === 'edit'}
              />
              <FormLabelInput
                name='name'
                rules={{
                  required:
                    modalType === 'add'
                      ? 'Please provide a statement name'
                      : false,
                }}
                label='Statement name'
                tooltip='tooltip info'
                extraLabelInputProps={{
                  disabled: modalType === 'edit',
                }}
              />
              <FormLabelInput
                name='description'
                rules={{
                  required:
                    modalType === 'add' || isNew
                      ? 'Please provide a description '
                      : false,
                }}
                label='Description'
                {...(isNew && { tooltip: 'Statement description' })}
                extraLabelInputProps={{
                  minRows: 2,
                  maxRows: 2,
                  multiline: true,
                  disabled: !isNew || modalType === 'edit',
                }}
              />
            </>
          ) : (
            <FormLabelSearchDropdown
              name='statementName'
              label='Statement name'
              rules={{ required: 'Please select a statement name' }}
              placeholder={'Search'}
              tooltip='tooltip info'
              disabled={!statementDropdown.length}
              dropDownItem={statementDropdown}
            />
          )}

          {isNew && (
            <FormLabelDropdown
              name='leadership_acumen'
              placeholder='Select leadership expertise'
              rules={{
                required:
                  modalType === 'add'
                    ? 'Please select a Leadership expertise'
                    : false,
              }}
              label='Leadership expertise'
              tooltip='tooltip info'
              dropDownItem={leadershipExpertiseData?.dropdownList ?? []}
              defaultValue={
                leadershipExpertiseData?.dropdownList
                  ? initialValues.leadership_acumen
                  : ''
              }
              disabled={modalType === 'edit'}
            />
          )}
          <FormInputSlider
            name={'proficiency'}
            label={'Set a Benchmark Score'}
            setValue={setValue}
            tooltip={<TooltipBenchmarkData />}
            defaultValue={initialValues.proficiency}
          />
        </FormWrapper>
      </ModalBody>
      <ModalActions>
        <Button
          btnType={'PRIMARY'}
          text={'Save'}
          type='submit'
          form='add-new-role'
          disabled={!!Object.values(errors).length}
        />
        <Button
          btnType={'SECONDARY'}
          text={'Cancel'}
          onClick={handleClose}
        />
      </ModalActions>
    </Modal>
  );
}
