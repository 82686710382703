import { Box, styled } from '@mui/material';
import React, { useRef } from 'react';
import TabContent from '../../atoms/tab/TabContent';
import HpTooltip from '../../atoms/tooltip/HpTooltip';
import { ITabContentProps } from '../../commons/types';
import RoleTable from '../../organisms/tables/RoleTable';
import { MRT_TableInstance } from 'material-react-table';
import Title from '../../atoms/title/Title';
import SubTitle from '../../atoms/title/SubTitle';
import ResetButton from '../../atoms/button/ResetButton';
import Button from '../../atoms/button/Button';
import { useActions } from '../../hooks/actions';
import OrganizationalImpactRLModal from '../../organisms/modals/Role/OrganizationalImpactRLModal';
import { useLazyGetRoleSkillsByCategoryQuery } from '../../store/api/leadership';

interface IProps {
  roleId?: string;
  skillType: string;
  client?: string;
  roleFunctionID?: string;
}

const StyledTitlesWrapper = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
}));

const StyledTitleWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledSubHeaderWrapper = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const StyledHeaderActionWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'end',
  alignItems: 'center',
  gap: '15px',
});

export default function BusinessOrganizationalImpact({
  index,
  value,
  roleId,
  roleFunctionID,
  client,
}: ITabContentProps & IProps) {
  const [getImpactStatements, { data: roleSkills, isLoading }] =
    useLazyGetRoleSkillsByCategoryQuery();

  React.useEffect(() => {
    if (value === 1) {
      getImpactStatements({
        client,
        roleId,
      });
    }
  }, [client, getImpactStatements, roleId, value]);

  const tableInstanceRef = useRef<MRT_TableInstance>(null);

  const onResetButtonClick = (): void => {
    tableInstanceRef.current !== null &&
      tableInstanceRef.current.resetColumnFilters();
  };

  const { openAddOrganizationalImpactRLModal } = useActions();

  return (
    <TabContent
      index={index}
      value={value}
    >
      <>
        <StyledTitlesWrapper>
          <StyledTitleWrapper>
            <Box sx={{ display: 'flex' }}>
              <Title
                text={'Business Impact: Organizational Impact'}
                sx={{
                  fontSize: '28px',
                }}
              />
              <Box sx={{ marginTop: '15px' }}>
                <HpTooltip title={'Business Impact: Organizational Impact'} />
              </Box>
            </Box>
            <StyledHeaderActionWrapper>
              <Button
                btnType={'PRIMARY'}
                text={'Add new statement'}
                onClick={() => openAddOrganizationalImpactRLModal()}
              />
            </StyledHeaderActionWrapper>
          </StyledTitleWrapper>
          <StyledSubHeaderWrapper>
            <SubTitle
              text={`${
                roleSkills?.organizationalImpactData?.length
                  ? roleSkills?.organizationalImpactData?.length
                  : '0'
              } total statements`}
            />
            <ResetButton
              onClick={onResetButtonClick}
              text='Reset'
            />
          </StyledSubHeaderWrapper>
        </StyledTitlesWrapper>
        {value === 1 && (
          <OrganizationalImpactRLModal roleFunctionID={roleFunctionID} />
        )}
        <RoleTable
          statementType='organizationalImpact'
          isLoading={isLoading}
          data={roleSkills?.organizationalImpactData}
          tableInstanceRef={tableInstanceRef}
        />
      </>
    </TabContent>
  );
}
